<template>
  <div v-if="sets.length" class="fw-light">
    <div v-for="(setGroup, setGroupIdx) in setGroups" :key="`key-${setGroupIdx}`">
      <span>{{ setGroup.length }}x{{ setGroup[0].reps }}</span>
      {{ getSetDetails(setGroup[0]) }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExerciseSetRep',
  props: {
    sets: {
      type: Array,
      required: true,
    },
  },
  computed: {
    setGroups() {
      const groups = [];
      let lastSet = '';
      this.sets.forEach((set) => {
        const setStr = JSON.stringify(set);
        if (setStr !== lastSet) {
          groups.push([set]);
          lastSet = setStr;
        } else {
          groups[groups.length - 1].push(set);
        }
      });
      return groups;
    },
  },
  methods: {
    getSetDetails(setGroup) {
      const { load, unit } = setGroup;
      if (load !== '' || unit === 'bodyweight') {
        const unitReadable = this.$options.filters.unitReadable(unit);
        return `@ ${load}${unitReadable}`;
      }
      return '';
    },
  },
};
</script>
